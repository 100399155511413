import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(BootstrapVue);

const routes = [
    {
        path: '/login',
        component: () => import('./components/Login.vue')
    }, {
        path: '/',
        component: () => import('./components/MainScreen.vue'),
        children: [
            {
                path: '/imports',
                name: 'imports',
                component: () => import('./components/TestGenerator')
            }, {
                path: '/shipments',
                component: () => import('./components/shipment/ShipmentBrowser.vue')
            }, {
                path: '/shipment/:id',
                name: 'shipment-editor',
                component: () => import('./components/shipment/ShipmentEditor.vue')
            }, {
                path: '/shipment',
                name: 'create-shipment',
                component: () => import('./components/shipment/ShipmentEditor.vue')
            }, {
                path: '/system-settings',
                component: () => import('./components/SystemSettings.vue')
            }, {
                path: '/places',
                component: () => import('./components/shipment/PlaceBrowser.vue')
            }, {
                path: '/place/:id',
                name: 'place-editor',
                component: () => import('./components/shipment/PlaceEditor.vue')
            }, {
                path: '/registers',
                component: () => import('./components/shipment/RegisterBrowser.vue')
            }, {
                path: '/register/:id',
                name: 'register-editor',
                component: () => import('./components/shipment/RegisterEditor.vue')
            }, {
                path: '/register',
                name: 'create-register',
                component: () => import('./components/shipment/RegisterEditor.vue')
            }, {
                path: '/test-generator',
                name: 'test-generator',
                component: () => import('./components/TestGenerator.vue')
            }, {
                path: '/sorting-centers',
                component: () => import('./components/shipment/SortingCenterBrowser.vue')
            }, {
                path: '/sorting-center/:id',
                name: 'sorting-center-editor',
                component: () => import('./components/shipment/SortingCenterEditor.vue')
            }, {
                path: '/sorting-center',
                name: 'create-sorting-center',
                component: () => import('./components/shipment/SortingCenterEditor.vue')
            }, {
                path: '/delivery-registers',
                component: () => import('./components/shipment/DeliveryRegisterBrowser.vue')
            }, {
                path: '/delivery-register/:id',
                name: 'delivery-register-editor',
                component: () => import('./components/shipment/DeliveryRegisterEditor.vue')
            }, {
                path: '/delivery-register',
                name: 'create-delivery-register',
                component: () => import('./components/shipment/DeliveryRegisterEditor.vue')
            },
        ]
    }];

const router = new VueRouter({
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active"
});

new Vue({
    render: h => h(App),
    router,
    VueResource,
    data: {
        apiUrl: 'https://sort-test-app.kak2c.ru',
        pagination: {
            page: 1,
            size: 20
        },
        authorities: [],
        admin: false
    },
    methods: {
        getHeaders() {
            return {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('jwt')
            }
        },
        handleErrorResponse(response) {
            if (response.status === 401) {
                this.$router.push("/login");
            } else {
                if (!response.body.success) {
                    alert(response.body.errorMsg ? response.body.errorMsg : response.body.message);
                } else {
                    alert("Ошибка соединения с сервером");
                }
            }
        },
        isAdmin() {
            return localStorage.getItem('user') === 'admin'
        },
        // getAuthorities() {
        //   this.$http.post(this.$root.accessUrl + '/oauth/check_token',
        //       {
        //         token: localStorage.getItem('jwt')
        //       },
        //       {
        //         emulateJSON: true,
        //         headers: {
        //           Authorization: 'Basic R2xvYmFsRGVsaXZlcnlUZXN0OnNlY3JldFRlc3Q=',
        //           'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //       }
        //   ).then(response => {
        //         if (response.body.authorities)
        //           this.authorities = response.body.authorities.slice();
        //         if (this.$root.authorities.includes('CLIENT'))
        //           localStorage.setItem("role", "CLIENT");
        //         if (this.$route.path === '/' && this.$root.authorities.includes('COURIER')) {
        //           this.$router.push('courier-next-task');
        //         }
        //         return this.authorities;
        //       }
        //   );
        // },
        formatDate(date) {
            return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        },
        prettyDateTime(date) {
            return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + "." + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + "." + date.getFullYear()
                + " " + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":" + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":" + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        },
        formatAddress(address) {
            let result = '';
            if (!address)
                return result;
            if (address.street)
                result += 'ул. ' + address.street;
            if (address.house)
                result += ' ' + address.house;
            if (address.block)
                result += ', корп. ' + address.block;
            if (address.building)
                result += ', стр. ' + address.building;
            if (address.flat)
                result += ', кв./офис ' + address.flat;
            return result;
        },
        cityOrSettlement(geography) {
            let result = '';
            if (!geography)
                return result;
            result += geography.country ? geography.country + ', ' : '';
            result += geography.region ? geography.region + ', ' : '';
            if (geography.city !== geography.region)
                result += geography.city ? 'г ' + geography.city + ', ' : '';
            result += geography.area ? 'р-н ' + geography.area + ', ' : '';
            result += geography.settlement ? geography.settlement : '';
            if (result.endsWith(", "))
                result = result.substring(0, result.length - 2);
            return result;
        },
        //default - pdf
        print(url, params, fileName, fileType) {
            let explorerFileType = fileType;
            if (fileType === 'xls') {
                explorerFileType = "application/xls";
                fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                fileName += ".xls"
            }
            if (!fileType) {
                fileType = "application/pdf";
                explorerFileType = fileType;
                fileName += ".pdf";
            }
            let pdfWindow;
            if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) {
                pdfWindow = window.open("", "_blank");
            }
            this.$http.get(this.$root.apiUrl + url,
                {
                    params: params,
                    headers: this.$root.getHeaders()
                })
                .then(response => {
                    if (response.body.success) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            let byteCharacters = atob(response.body.pdf);
                            let byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            let byteArray = new Uint8Array(byteNumbers);
                            let blob = new Blob([byteArray], {type: explorerFileType});
                            window.navigator.msSaveOrOpenBlob(blob, fileName);
                        } else {
                            pdfWindow.document.write(
                                "<iframe width='100%' height='100%' src='data:" + fileType + ";base64, " + encodeURI(response.body.pdf) + "'></iframe>"
                            );
                        }
                    } else {
                        alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                    }
                }, response => {
                    this.$root.handleErrorResponse(response);
                });
        },
        //Сортировка колонок в таблице для nested значений (Пример: geography.city)
        sortCompare(a, b, key) {
            let stingA = this.toString(a);
            let stringB = this.toString(b);
            //Для чисел
            if (typeof a[key] === 'number' && typeof b[key] === 'number') {
                return a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0)
            } else {
                //Для Строк
                if (stingA.hasOwnProperty(key) && stringB.hasOwnProperty(key)) {
                    return (stingA[key]).toString().localeCompare((stringB[key]).toString(), undefined, {
                        numeric: true
                    })
                } else {
                    if (stingA.hasOwnProperty(key) && !stringB.hasOwnProperty(key)) {
                        return -1;
                    }
                    return 1;
                }
            }
        }
    }
}).$mount('#app')
