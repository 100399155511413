<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: 'app',
    components: {

    }
  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
  }
  html, body {
    overflow: no-display;
  }
  .btn-primary {
    color: #fff !important;
    background-color: #1227aa !important;
    border-color: #1227aa !important;
  }
  .page-link {
    color: #1227aa;
  }
  html {
    height: 100vh;
  }
  body {
    height: 100vh;
  }
  .nav-link.active {
    background-color: #1227aa !important;
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #1227aa !important;
  }
  .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #5861aa !important;
  }
</style>